import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "rootRef"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v(_vm._s(_vm.$t("common.search")))]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v(_vm._s(_vm.$t("common.reset")))])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "id",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      },
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "istop",
      fn: function fn(istop) {
        return [_c("span", [_vm._v(_vm._s(istop == 0 ? _vm.$t("common.no") : _vm.$t("common.yes")))])];
      }
    }, {
      key: "type",
      fn: function fn(type) {
        return [type == 1 ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("common.video")))]) : _c("span", [_vm._v(_vm._s(_vm.$t("common.photo")))])];
      }
    }, {
      key: "postid",
      fn: function fn(postid, _ref) {
        var contenturl = _ref.contenturl,
          type = _ref.type;
        return [_c("span", {
          staticStyle: {
            color: "#3270f5",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.showVideoDialog(contenturl, type);
            }
          }
        }, [_vm._v(_vm._s(postid))])];
      }
    }, {
      key: "parentid",
      fn: function fn(parentid) {
        return [_c("span", {
          staticStyle: {
            color: "#3270f5",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.handleSearchById(parentid);
            }
          }
        }, [_vm._v(_vm._s(parentid))])];
      }
    }, {
      key: "content",
      fn: function fn(content) {
        return [_c("div", {
          staticClass: "reply-content",
          domProps: {
            innerHTML: _vm._s(content)
          }
        })];
      }
    }, {
      key: "username",
      fn: function fn(username, _ref2) {
        var userid = _ref2.userid,
          creator = _ref2.creator,
          fansnum = _ref2.fansnum,
          usertype = _ref2.usertype;
        return [_c("a-tooltip", {
          attrs: {
            placement: "top"
          }
        }, [_c("template", {
          slot: "title"
        }, [_c("span", {
          directives: [{
            name: "copy",
            rawName: "v-copy",
            value: userid,
            expression: "userid"
          }],
          staticStyle: {
            cursor: "pointer"
          }
        }, [_vm._v(_vm._s(userid))])]), _c("div", [usertype > 0 ? _c("img", {
          staticStyle: {
            position: "relative",
            top: "-2px",
            "margin-right": "2px"
          },
          attrs: {
            src: _vm.certified,
            alt: ""
          }
        }) : _vm._e(), _c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          }
        }, [_vm._v(_vm._s(username || "---"))])]), creator ? _c("span", {
          staticStyle: {
            display: "block",
            color: "#ff4d4f"
          }
        }, [_vm._v("(Creator)")]) : _vm._e()], 2), _c("div", [_vm._v(_vm._s(_vm.$t("common.fans")) + "：" + _vm._s(fansnum))])];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [_c("span", {
          style: {
            color: _vm.getTextColor("status", status)
          }
        }, [_vm._v(_vm._s(_vm.getOptionItemNameById("status", status)))])];
      }
    }, {
      key: "action",
      fn: function fn(action, _ref3) {
        var id = _ref3.id,
          istop = _ref3.istop,
          status = _ref3.status;
        return [_c("div", {
          staticClass: "btn-action-wrap"
        }, [status == 0 ? _c("a-button", {
          attrs: {
            type: "primary",
            ghost: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(0, id, istop);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.manage")) + " ")]) : _vm._e(), status == 1 ? _c("a-button", {
          staticStyle: {
            color: "#67c23a",
            "border-color": "#67c23a"
          },
          attrs: {
            ghost: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(1, id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.pass")) + " ")]) : _vm._e(), [2, 3].includes(status) ? _c("a-button", {
          staticStyle: {
            color: "#e6a23c",
            "border-color": "#e6a23c"
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(2, id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.recover")) + " ")]) : _c("a-button", {
          attrs: {
            type: "danger",
            ghost: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleClick(3, id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.delete")) + " ")]), _c("a-button", {
          on: {
            click: function click($event) {
              return _vm.handleClick(4, id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.logs")) + " ")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle,
      width: _vm.modalWidth,
      footer: null,
      centered: "",
      "confirm-loading": _vm.confirmLoading,
      "get-container": function getContainer() {
        return _vm.$refs.rootRef;
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_vm.activeType == 0 ? _c("a-form-model", {
    attrs: {
      model: _vm.form,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-checkbox", {
    model: {
      value: _vm.form.istop,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "istop", $$v);
      },
      expression: "form.istop"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("comment.setAsPinnedComment")) + " ")])], 1), _c("a-form-model-item", {
    attrs: {
      label: _vm.$t("common.remark")
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      "auto-size": {
        minRows: 1,
        maxRows: 6
      },
      maxLength: 120,
      placeholder: _vm.$t("comment.enterTheRemark")
    },
    model: {
      value: _vm.form.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "memo", $$v);
      },
      expression: "form.memo"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      gap: "10px"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.updatetop(_vm.editId);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)])], 1) : _vm._e(), _vm.activeType == 3 ? _c("a-form-model", {
    ref: "form2",
    attrs: {
      model: _vm.form2,
      rules: _vm.rules,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: _vm.$t("common.reason"),
      prop: "reason"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: _vm.$t("comment.chooseReasonTemplate")
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  }, _vm._l(_vm.reasonTemplates, function (item) {
    return _c("a-select-option", {
      key: item.value,
      attrs: {
        value: item.value,
        title: item.label
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c("a-input", {
    attrs: {
      type: "textarea",
      maxLength: 120,
      "auto-size": {
        minRows: 1,
        maxRows: 6
      }
    },
    model: {
      value: _vm.form2.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "reason", $$v);
      },
      expression: "form2.reason"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: _vm.$t("common.remark")
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      "auto-size": {
        minRows: 1,
        maxRows: 6
      },
      maxLength: 120,
      placeholder: _vm.$t("comment.enterTheRemark")
    },
    model: {
      value: _vm.form2.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "memo", $$v);
      },
      expression: "form2.memo"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      gap: "10px"
    }
  }, [_c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading3
    },
    on: {
      click: function click($event) {
        return _vm.handleDel(_vm.editId);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)])], 1) : _vm._e(), _vm.activeType == 4 ? [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource2,
      columns: _vm.tableColumns2,
      rowKey: _vm.rowKey,
      scroll: {
        x: "100%",
        y: 500
      },
      loading: _vm.loading2
    }
  }), _c("pagination", {
    attrs: {
      total: _vm.total2,
      page: _vm.page2,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange2,
      onShowSizeChange: _vm.onPageSizeChange2
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page2 = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })] : _vm._e()], 2), _c("a-modal", {
    attrs: {
      title: _vm.$t("comment.previewVideo"),
      width: 1000,
      footer: null,
      centered: ""
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_vm.type == 2 ? _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("h3", [_vm._v(_vm._s("".concat(_vm.$t("common.total"), "\uFF1A").concat(_vm.contenturl.length)))]), _c("div", {
    staticClass: "img-list"
  }, _vm._l(_vm.contenturl, function (item, index) {
    return _c("div", {
      key: item,
      staticClass: "img-box",
      on: {
        click: function click($event) {
          return _vm.hevueImgPreview(index);
        }
      }
    }, [_c("img", {
      attrs: {
        src: item,
        alt: ""
      }
    })]);
  }), 0)]) : _c("video", {
    staticClass: "post-video",
    attrs: {
      src: _vm.contenturl,
      controls: ""
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };