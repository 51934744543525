import { POST, GET } from "@/utils/util";
var CommentManageApi = {
  // 获取评论列表
  getCommentList: function getCommentList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/postcomment/adminfindpage", params);
  },
  // 评论置顶
  updatetop: function updatetop() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/postcomment/updatetop", params);
  },
  // 评论批量通过-批量删除
  updatestatus: function updatestatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/postcomment/updatestatus", params);
  },
  // 评论恢复
  recoverycomment: function recoverycomment() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/postcomment/recoverycomment", params);
  },
  // 日志列表
  getCommentLogList: function getCommentLogList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/postcomment/getcommentlog", params);
  }
};
export default CommentManageApi;